import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { LicenseDetailsProvider } from "./utils/licenseContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LicenseDetailsProvider>
    <App />
  </LicenseDetailsProvider>
);
